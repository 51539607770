<template>
    <form class="form" novalidate="novalidate" id="st_staff_form">
        <div>
            <div class="card mb-10">
                <!--begin::Header-->
                <div class="card-header border-0">
                    <p class="st-dark-heading mb-5">
                        {{ $t("PROFILE.SECTIONS.ACCOUNT_DETAILS.HEADER") }}
                    </p>
                </div>
                <!--end::Header-->

                <!--begin::Body-->
                <div class="card-body pt-0">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="form-group">
                                <label>
                                    {{ fields.last_name.label }} *
                                </label>
                                <div label="">
                                    <st-input-text
                                        v-model="model[fields.last_name.name]"
                                        :ref="fields.last_name.name"
                                        :name="fields.last_name.name"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <label>
                                    {{ fields.first_name.label }} *
                                </label>
                                <div label="">
                                    <st-input-text
                                        v-model="model[fields.first_name.name]"
                                        :ref="fields.first_name.name"
                                        :name="fields.first_name.name"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <label>
                                    {{ $t("FORM_FIELDS.EMAIL") }}
                                </label>
                                <div label="">
                                    <st-input-text
                                        :value="userData.user_email"
                                        ref="email"
                                        name="email"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-4">
                            <div class="form-group">
                                <label>
                                    {{ fields.phone_number.label }} *
                                </label>
                                <div label="">
                                    <st-input-text
                                        v-model="model[fields.phone_number.name]"
                                        :ref="fields.phone_number.name"
                                        :name="fields.phone_number.name"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <label>
                                    {{ fields.identification_number.label }} *
                                </label>
                                <div label="">
                                    <st-input-text
                                        v-model="model[fields.identification_number.name]"
                                        :ref="fields.identification_number.name"
                                        :name="fields.identification_number.name"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <label>
                                    {{ fields.status.label }}
                                </label>
                                <div label="">
                                    <b-form-select
                                        v-model="model[fields.status.name]"
                                        :options="fields.status.options"
                                        :ref="fields.status.name"
                                        :name="fields.status.name"
                                        disabled
                                >
                                </b-form-select>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-4">
                            <div class="form-group">
                                <label>
                                    {{ $t("FORM_FIELDS.ORGANISATION") }}
                                </label>
                                <div label="">
                                    <st-input-text
                                        :value="userData.organisation_name"
                                        ref="organisation"
                                        name="organisation"
                                        disabled
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <label>
                                    {{ $t("FORM_FIELDS.DEPARTMENT") }}
                                </label>
                                <div label="">
                                    <st-input-text
                                        :value="userData.departments ? userData.departments.join(', ') : ''"
                                        ref="department"
                                        name="department"
                                        disabled
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <label>
                                    {{ $t("FORM_FIELDS.FUNCTION") }}
                                </label>
                                <div label="">
                                    <st-input-text
                                        :value="userData.functions ? userData.functions.join(', ') : ''"
                                        ref="function"
                                        name="function"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end::Body-->
            </div>

            <account-details :details="userData.responsibilities ? userData.responsibilities : []" />
        </div>
    </form>
</template>

<script>
import AccountDetails from "@/modules/profile/components/AccountDetails";
import { mapGetters } from "vuex";
import { ProfileModel } from "@/modules/profile/profile-model";
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";

const { fields } = ProfileModel;

const formSchema = new FormSchema([
    fields.id,
    fields.user_type_name,
    fields.first_name,
    fields.last_name,
    fields.identification_number,
    fields.phone_number,
    fields.status,
]);

export default {
    name: "StaffProfileForm",
    components: {
        AccountDetails,
    },
    data() {
        return {
            model: null,
            rules: formSchema.rules(),
            fields: fields,
        };
    },
    computed: {
        ...mapGetters({
            currentUser: "auth/currentUser",
            accountDetails: "auth/accountDetails",
        }),
        userData() {
            return { ...this.accountDetails, ...this.currentUser };
        },
    },
    created() {
        this.model = formSchema.initialValues(this.userData);
    },
    mounted() {
        this.fv = createFormValidation("st_staff_form", this.rules);
    },
};
</script>
