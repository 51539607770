<template>
    <st-section :header="$t('PROFILE.SECTIONS.IDENTITY_CARD.HEADER')">
        <template #body>
            <div class="row">
                <div class="col-12 col-md-2">
                    <div class="form-group">
                        <label>
                            {{ fields.id_card_serial.label }} *
                        </label>
                        <div label="">
                            <st-input-text
                                v-model="model[fields.id_card_serial.name]"
                                :ref="fields.id_card_serial.name"
                                :name="fields.id_card_serial.name"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-2">
                    <div class="form-group">
                        <label>
                            {{ fields.id_card_number.label }} *
                        </label>
                        <div label="">
                            <st-input-text
                                v-model="model[fields.id_card_number.name]"
                                :ref="fields.id_card_number.name"
                                :name="fields.id_card_number.name"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="form-group">
                        <label>
                            {{ fields.id_card_expiration_date.label }} *
                        </label>
                        <div label="">
                            <st-datepicker
                                v-model="model[fields.id_card_expiration_date.name]"
                                :ref="fields.id_card_expiration_date.name"
                                :name="fields.id_card_expiration_date.name"
                                :min="min"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="form-group">
                        <label>
                            {{ fields.id_card_issuer.label }} *
                        </label>
                        <div label="">
                            <st-input-text
                                v-model="model[fields.id_card_issuer.name]"
                                :ref="fields.id_card_issuer.name"
                                :name="fields.id_card_issuer.name"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </st-section>
</template>

<script>
import { idCard } from '@/shared/utils/id-card';

export default {
    props: {
        model: {
            type: Object,
        },
        fields: {
            type: Object,
        },
    },
    data() {
        return {
            min: idCard.expirationDate
        }
    },
}
</script>
