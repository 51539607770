<template>
    <form ref="staff_enrollment_form" 
        class="d-none" 
        :action="actionUrl" 
        method="post">
        <textarea hidden name="json" type="text" v-model="videoEnrollmentToken"></textarea>
    </form>
</template>

<script>
import certSignConfig from "@/config/certsign.config.json";

export default {
    name: 'StaffEnrollmentForm',
    props: {
        videoEnrollmentToken: {
            type: String,
            require: true,
        },
    },
    data() {
        return {
            actionUrl: certSignConfig.VIDEO_ENROLLMENT_URL,
        }
    },
    methods: {
        submit() {
            this.$refs.staff_enrollment_form.submit();
        },
    },
}
</script>
