<template>
    <div class="card">
        <!--begin::Header-->
        <div class="card-header border-0">
            <p class="st-dark-heading">
                {{ $t('PROFILE.SECTIONS.RESPONSIBILITIES.HEADER') }}
            </p>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
          <div class="card-body pt-0">
            <div class="row">
                <div class="col-md-6">
                    <ul>
                        <li
                            v-for="responsibility in details"
                            :key="responsibility.id"
                            class="mb-1"
                        >
                            {{ responsibility }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!--end: Card Body-->
    </div>
</template>

<script>
export default {
    name: 'AccountDetails',
    props: {
        details: {
            type: Array,
            required: true,
        },
    },
};
</script>
