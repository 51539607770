<template>
    <form class="form" novalidate="novalidate" id="st_foreigner_form">
        <div class="card w-100">
            <!--begin::Header-->
            <div class="card-header border-0">
                <h5 class="card-title">
                    {{ $t("PROFILE.SECTIONS.PERSONAL_DATAS.HEADER") }}
                </h5>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body pt-0">
                <div class="row mb-6">
                    <!--begin::Content-->
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>
                                {{ fields.passport_number.label }} *
                            </label>
                            <st-input-text
                                v-model="model[fields.passport_number.name]"
                                :ref="fields.passport_number.name"
                                :name="fields.passport_number.name"
                            />
                        </div>
                        <div class="form-group">
                            <label>
                                {{ fields.last_name.label }} *
                            </label>
                            <st-input-text
                                v-model="model[fields.last_name.name]"
                                :ref="fields.last_name.name"
                                :name="fields.last_name.name"
                            />
                        </div>
                        <div class="form-group">
                            <label>
                                {{ fields.first_name.label }} *
                            </label>
                            <st-input-text
                                v-model="model[fields.first_name.name]"
                                :ref="fields.first_name.name"
                                :name="fields.first_name.name"
                            />
                        </div>
                        <div class="form-group">
                            <label>
                                {{ fields.country.label }} *
                            </label>
                            <st-autocomplete
                                v-model="model[fields.country.name]"
                                :options="fields.country.options"
                                :ref="fields.country.name"
                                :name="fields.country.name"
                                value-field="text"
                                text-field="text"
                                @change="onChange(fields.country)"
                            ></st-autocomplete>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>
                                {{ fields.city.label }} *
                            </label>
                            <st-input-text
                                v-model="model[fields.city.name]"
                                :ref="fields.city.name"
                                :name="fields.city.name"
                            />
                        </div>
                        <div class="form-group">
                            <label>
                                {{ fields.address.label }} *
                            </label>
                            <st-input-text
                                v-model="model[fields.address.name]"
                                :ref="fields.address.name"
                                :name="fields.address.name"
                            />
                        </div>
                        <div class="form-group">
                            <label>
                                {{ fields.email.label }} *
                            </label>
                            <st-input-text
                                v-model="model[fields.email.name]"
                                :ref="fields.email.name"
                                :name="fields.email.name"
                                disabled
                            />
                        </div>
                        <div class="form-group">
                            <label>
                                {{ fields.phone_number.label }} *
                            </label>
                            <st-input-text
                                v-model="model[fields.phone_number.name]"
                                :ref="fields.phone_number.name"
                                :name="fields.phone_number.name"
                                phone-validation
                            />
                        </div>
                    </div>
                    <!--end::Content-->
                </div>
            </div>
            <!--end::Body-->
        </div>
    </form>
</template>

<script>
import { mapGetters } from "vuex";
import { ProfileModel } from "@/modules/profile/profile-model";
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";

const { fields } = ProfileModel;

const formSchema = new FormSchema([
    fields.id,
    fields.user_type_name,
    fields.passport_number,
    fields.first_name,
    fields.last_name,
    fields.country,
    fields.city,
    fields.address,
    fields.email,
    fields.phone_number,
]);

export default {
    name: "ForeignerForm",
    data() {
        return {
            rules: formSchema.rules(),
            model: null,
            fields: fields
        }
    },
    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser'
        }),
    },
    methods: {
        onChange(field) {
            this.fv.revalidateField(field.name, true);
        },
    },
    created() {
        this.model = formSchema.initialValues(this.currentUser);
    },
    mounted() {
        this.fv = createFormValidation("st_foreigner_form", this.rules);
    },
};
</script>
